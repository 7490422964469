
<template>
  <v-row class="height100" justify="start">
    <v-col class="py-0 px-0">
      <v-card
        outlined
        rounded="xl"
        :color="navColor"
        :style="`height: ${rootHeight + 95}px;
          position: relative;
          width: 100%;
          border: ${borderStyle}`"
      >
        <SnackBar
          :snackMsg="snackMsg"
          :snackOn="snackOn"
          :snackTop="true"
          @close="snackOn = false"
        />

        <v-container class="py-2">
          <v-row
            justify="start"
            class="px-1"
          >
            <div class="d-flex">
              <v-btn
                icon
                :disabled="this.from === '/'"
                @click="$router.go(-1)"
              >
                <v-icon>
                  {{ mdiArrowLeft }}
                </v-icon>
              </v-btn>
              <v-list-item v-if="currentRoom.type === 'CHAT'">
                <v-list-item-avatar size="30">
                  <v-img :src="getResourceUrl(currentRoom.thumbnail)">
                    <template v-slot:placeholder>
                      <v-skeleton-loader
                        class="mx-auto"
                        height="40"
                        width="40"
                        type="avatar"
                      />
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content class="py-0">
                  <v-list-item-title v-text="currentRoom.name" />
                  <v-list-item-description
                    v-text="membersLabel"
                    :style="`font-size: 12px; color: grey;`"
                  />
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-row>
        </v-container>

        <div :style="`border-bottom: 1px solid ${dividerColor};`"></div>
        
        <v-fade-transition hide-on-leave>
          <ChatContainer
            v-if="!loading && $route.query.id"
            containerClass="pt-0"
            :height="containerHeight - heightAdjustment"
            :outerHeight="outerHeight - 40"
            :innerTop="containerHeight + 35"
            menuClass="menu--top"
            :room="currentRoom"
            :conversation="currentConversation"
            @heightChange="heightChange"
          />
        </v-fade-transition>

        <v-fade-transition hide-on-leave>
          <Scrollbar
            :flexible="true"
            :thin="true"
            :style="`height: ${rootHeight + 40}px;`"
            class="px-4"
          >
            <v-container
              v-if="!$route.query.id"
              fill-height
              :style="`height: ${$vuetify.breakpoint.xl ? 88  : 85}%;`"
            >
              <v-row justify="center">
                <v-img
                  contain
                  src="@assets/images/DALL·E 2023-10-23 00.59.20-Watercolor.png"
                  :style="`opacity: ${$vuetify.theme.dark ? 0.9 : 0.35 };
                  height: auto;
                  max-width: 80%;`"
                />
              </v-row>
            </v-container>
          </Scrollbar>

        </v-fade-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Scrollbar, SnackBar } from '@components/App'
import ChatContainer from '@components/Conversation/ChatContainer'
import { mdiArrowLeft } from '@mdi/js'
import API from '@api'
import { getResourceUrl } from '@utils'

export default {
  components: {
    SnackBar,
    Scrollbar,
    ChatContainer
  },
  
  created() {
    this.setChat()
  },
  
  mounted() {
    this.$socket.conversation.connect()
  },

  beforeDestroy() {
    this.$socket.conversation.disconnect()
  },

  data: () => ({
    mdiArrowLeft,

    heightAdjustment: 10,

    
    snackOn: false,
    snackMsg: '',
    drawer: null,
    loading: true,
    currentConversation: {},
    messages: [],
  }),

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      from: 'from',
      user: 'user',
      myProfileId: 'profile/id',
      avatar: 'profile/avatar',
      currentRoom: 'rooms/currentRoom',
    }),

    containerHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight)) - 100
    },

    outerHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight))
    },

    rootHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight)) - 80
    },

    dividerColor() {
      return this.$vuetify.theme.dark ? '#212121' : '#EEEEEE'
    },

    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    },

    btnColor() {
      return this.$vuetify.theme.dark ? '' : 'primary'
    },

    navColor() {
      return !this.$vuetify.theme.dark
        ? 'grey lighten-5'
        : '#1a1a1a'
    },

    txtFieldColor() {
      return !this.$vuetify.theme.dark
        ? 'grey lighten-2'
        : 'grey darken-4'
    },

    membersLabel() {
      const { members, type } = this.currentRoom
      if (!members || !this.currentRoom.members.length) return ''
      return members.map(({profile}) => profile.name).join(', ')
    }
  },

  watch: {
    '$route' () {
      this.setChat()
    }
  },

  methods: {
    getResourceUrl,
    ...mapMutations({
      SET_CURRENT_ROOM: 'rooms/setCurrentRoom',
      ADD_GROUP: 'social/ADD_GROUP',
      ADD_MEMBERSHIP: 'social/ADD_MEMBERSHIP'
    }),

    heightChange(heightDifference) {
      /*
      * HEIGHT ADJUSMENT LOGIC
      * 
      * Adjusts the height of the ChatContainer
      * to follow the height changes in the
      * text editor.
      */
      if (Math.abs(heightDifference) > 2)
        this.heightAdjustment += heightDifference
    },

    async setChat() {
      this.loading = true
      // no chat to set if there is no query
      if (!this.$route.query.id) return;

      try {
        const [conversation] = await API().get(`conversations/${this.user.id}`, {
          params: {
            query: {
              _id: {
                $eq: this.$route.query.id
              }
            }
          }
        })

        this.currentConversation = conversation

        const currentRoom = await API().get(`room/${this.user.id}`, {
          params: {
            id: conversation.room
          }
        })
        
        // let memberships = await API().get(`members/${this.user.id}`, {
        //   params: {
        //     query: {
        //       room: {
        //         $eq: currentRoom.id
        //       }
        //     }
        //   }
        // })

        // const profileQuery = {
        //   _id: {
        //     $in: memberships.map(({ profile }) => profile)
        //   },
        // }
        // // For friendship room, keep my own profile
        // // otherwise filter it
        // if (currentRoom.type != 'FRIENDSHIP')
        //   profileQuery._id.$ne = this.myProfileId
        
        // currentRoom.members = await API().get(`profiles/${this.user.id}`, {
        //   params: {
        //     query: profileQuery,
        //     select: mongoSelect.profiles
        //   }
        // })
        this.SET_CURRENT_ROOM(currentRoom)
        this.currentRoom = currentRoom

      } catch(err) {
        this.snackMsg = 'chatNotFound'
        this.snackOn = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>